import { FC, useEffect, useMemo, useState } from 'react';
import { useFormRendererInfo } from '../../../contexts/FormRendererContext';
import { useFormRendererDesign } from '../../../contexts/FormRendererDesignContext';
import { FormRendererMode, FormStepTitleProviderProps } from '../../../contexts/FormRendererDesignContextTypes';
import StringUtils from '../../../utils/StringUtils';
import EditIcon from '../../shared/icon/EditIcon';
import EyeIcon from '../../shared/icon/EyeIcon';
import { Heading, HeadingSize } from '../../shared/text/Heading';
import Tooltip from '../../shared/Tooltip';
import { EventSystem } from '../../../events/EventSystem';
import CommentEvent from '../../../events/QuestionCommentEvent';
import { useFormAction } from '../../../contexts/FormActionContext';
import LanguageUtils from '../../../utils/LanguageUtils';
import IconBadge from '../../shared/icon/IconBadge';
import CommentIcon from '../../shared/icon/CommentIcon';
import { useCurrentActivityCommentsStats } from '../../../global-state/Forms';

const TITLE_TRUNCATE_LENGTH = 80;

const FormStepTitle: FC<FormStepTitleProviderProps> = (props) => {
  const { step, mode } = props;
  const { changeMode, isDisplayedInline, featureToggles, isCommentsVisible, rootContext, clientFormId, language } = useFormRendererInfo();
  const {
    slots: { sectionTitleEnd, sectionTitleStart },
  } = useFormRendererDesign();

  const [highlighted, setHighligheted] = useState(false);

  const commentStats = useCurrentActivityCommentsStats((x) => x.value);

  const { currentAction } = useFormAction();

  const commentCount = useMemo(() => commentStats.sectionCommentsCounts[step.id], [commentStats.sectionCommentsCounts, step.id]);

  const stepTitle = useMemo(() => {
    return `${step?.number} ${LanguageUtils.getTranslation('title', step.translations, language)}`;
  }, [step?.number, step.translations, language]);

  useEffect(() => {
    const handleCommentSectionOpen = (event: CommentEvent) => {
      setHighligheted(event.sectionId === step.id && !event.sourceId);
    };

    const handleCommentOpen = () => {
      setHighligheted(false);
    };

    EventSystem.listen('question-comment-open', handleCommentSectionOpen);
    EventSystem.listen('question-comment-new', handleCommentOpen);

    return () => {
      EventSystem.stopListening('question-comment-open', handleCommentSectionOpen);
      EventSystem.stopListening('question-comment-new', handleCommentOpen);
    };
  }, [step.id]);

  return (
    <div
      className={`${
        featureToggles?.enableTotalComments && highlighted && isCommentsVisible ? 'bg-accent-light-mid' : ''
      } border-gray-5 mx-4 my-2 flex items-center justify-between border-b-2 pl-2 pt-2 ${
        rootContext?.clientFormId === clientFormId ? 'sticky top-[-1px] z-40 bg-white' : ''
      }`}
    >
      <div className="flex items-center gap-4">
        <Tooltip text={stepTitle.length > TITLE_TRUNCATE_LENGTH ? stepTitle : null}>
          {(tooltip) => (
            // if currentAction then we know it's rendering as a inline subform, therefore a smaller title size
            <Heading size={HeadingSize.H2} actualSize={currentAction ? HeadingSize.H6 : HeadingSize.H5} className="flex pb-3">
              <span {...tooltip} className="font-medium">
                {StringUtils.truncate(stepTitle, TITLE_TRUNCATE_LENGTH)}
              </span>
            </Heading>
          )}
        </Tooltip>
        {sectionTitleStart}
      </div>
      <div className="flex items-center gap-4">
        {sectionTitleEnd}
        {!featureToggles?.disableModeToggle &&
          !isDisplayedInline &&
          (mode === FormRendererMode.EditView ? (
            <EyeIcon className="h-6 w-6 text-black" onClick={() => changeMode(FormRendererMode.PreviewView, step.id)} />
          ) : (
            <EditIcon className="h-6 w-6 text-black" onClick={() => changeMode(FormRendererMode.EditView, step.id)} />
          ))}
        {featureToggles?.enableTotalComments && (
          <IconBadge
            icon={<CommentIcon className="h-4 w-4 flex-shrink-0" />}
            text={commentCount}
            onClick={() => EventSystem.fireEvent('question-comment-open', { sectionId: step.id, sourceId: '' })}
          />
        )}
      </div>
    </div>
  );
};

export default FormStepTitle;
